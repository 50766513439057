
:root {
    --black: #160133;
    --black-haze: #f7f7f7;
    --black-rock: rgba(22, 1, 51, 1);
    --black-rock-2: #130331;
    --blackcurrant: rgba(45, 44, 60, 1);
    --blue-violet: #6352e8;
    --electric-violet: rgba(87, 0, 234, 1);
    --chicago: #5c5c5c;
    --ebb: #e3e3e3;
    --white: #ffffff;
    --light-purple: #D4D0F3;
    --danger: #D80404;
    --success: #04D835;
    --dark-gray: #00000029;
    --pixie-powder: #d9d9d9;
    --yellow-orange: rgba(255, 172, 75, 1);
    --gunsmoke: rgba(134, 134, 134, 1);
    --moon-raker: rgba(212, 208, 243, 1);
    --concrete: rgba(242, 242, 242, 1);
    --color-d80404: #D80404;
    --color-d9d9d9: #D9D9D9;
    --color-04d835: #04D835;
    --color-000000: #000000;
    --color-d4d0f3: #D4D0F3;
    --color-6352e871: #6352E871;
    --color-00000099: #00000099;
    --color-868686: #868686;
    --color-e3e3e3: #E3E3E3;
    --color-f2f2f2: #F2F2F2;
    --color-6352e8: #6352E8;
    --color-160133: #160133;
    --color-ffffff: #FFFFFF;
    --color-5c5c5c: #5C5C5C;
    --color-120b2b: #120B2B;
    --color-23036a: #23036A;
    --color-2d2c3c: #2D2C3C;
    --color-e8526f: #E8526F;
    --color-00000061: #00000061;

    --font-size-s: 7px;
    --font-size-m: 10px;
    --font-size-l: 12px;
    --font-size-xl: 17px;
    --font-size-xxl: 20px;
    --font-size-xxxl: 24px;

    --font-family-poppins: "Poppins", Helvetica;
    --font-family-Poppins: "Poppins", Helvetica;

    /* Font/text values */
    --font-style-normal: normal;
    --font-weight-normal: normal;
    --font-weight-bold: bold;
    --font-size-8: 8px;
    --font-size-9: 9px;
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-17: 17px;
    --font-size-18: 18px;
    --font-size-22: 22px;
    --character-spacing-0: 0px;
    --line-spacing-10: 10px;
    --line-spacing-12: 12px;
    --line-spacing-13: 13px;
    --line-spacing-14: 14px;
    --line-spacing-16: 16px;
    --line-spacing-25: 25px;
    --text-transform-uppercase: uppercase;
    
        touch-action: pan-x pan-y;
        height: 100% ;
      
}

@keyframes bull_shake_left {
    0%, 100% {
        transform: rotate(0deg);
        transform-origin: 0 50%;
    }
    10% {
        transform: rotate(2deg);
    }
    20%, 40%, 60% {
        transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        transform: rotate(4deg);
    }
    80% {
        transform: rotate(-2deg);
    }
    90% {
        transform: rotate(2deg);
    }
}

@keyframes bull_shake_right {
    0%, 100% {
        transform: rotate(0deg);
        transform-origin: 100% 50%;
    }
    10% {
        transform: rotate(2deg);
    }
    20%, 40%, 60% {
        transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        transform: rotate(4deg);
    }
    80% {
        transform: rotate(-2deg);
    }
    90% {
        transform: rotate(2deg);
    }
}
 /* Modal Content */
 .modal-content {
    background-color: #fefefe;
    top: 30%;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
  }
  .modal-content{
    
    border-radius: 100px 0px 100px 100px !important;
}
.cmpwidget{
    background-color: transparent !important;
}
@media only screen and (max-width: 1090px) {
    .modal-content {
        width: 85%;
        padding: 10px;
    }
  }
