/* Results Modal css starts here */

.resultsModal .resultsModalBody {padding: 0px 0px 5px !important;display: flex !important;}
.resultsModal .modal-content .modal-body .resultsModalBody p{font-size: 13px !important;}
/* .resultsModal .resultModalBtn {margin-top: 0px !important;margin-bottom: 0px !important;}
.resultsModal .resultModalBtn button {height: 26px !important;padding: 5px 10px !important;font-size: 13px !important;} */
.resultsModal .modal-dialog .modal-content {margin-top: 1% !important;padding: 8px 8px !important;}

/* Results Modal css ends here */

@media screen and (min-device-width: 768px) and (max-device-width: 991px) { 
    .BigDiv_Responsive .textbox .low {
        left: 17% !important;
    }
    .BigDiv_Responsive .textbox .high {
        right: 15.5% !important;
    }
    /* .cpCardGraph {
        margin-top: 40% !important;
    } */
}

@media only screen and (max-width: 768px) {
    .iconText {
      font-size: 10px !important;
    }
  
    .iconText .icon {
      width: 45px !important;
      height: 35px !important;
    }
   

    /* .icon{
        margin-right: 35px !important;
    } */
  }

@media only screen and (max-width: 991px) {
    .frameCoinHeader {
        border-radius: 0px 0px 60px 0px !important;
        padding: 10px 15px 5px !important;
    }

     .BigDiv_Responsive .textbox .low {
        left: 17% !important;
        top: 43% !important;
    }
    .BigDiv_Responsive .textbox .high {
        right: 17% !important;
        top: 43% !important;
    }

    .rangeBox {
        width:240px !important;
        height: 247px !important;
    }


    .frameCoinHeader p {
        font-size: 12px !important;
    }

    .frameCoinshow .confetti-button {
        max-width: 120px;
        height: 50px;
    }

    .frameCoinshow .frameCoinHeader .frameheadLogo>div {
        text-align: center;
    }

    .frameCoinshow .frameCoinHeader .frameheadLogo img {
        max-width: 50px;
        max-height: 50px;
        margin: 0px auto;
    }

    .frameCoinshow .frameHeadcpLogo img {
        max-width: 50px;
    }

    .timeSlotes .timeBlocks {
        width: 60px !important;
        height: 60px !important;
        flex: 0 0 60px;
    }

    .timeSlotes .timeBlocks>div {
        margin: 0px;
    }

    .timeSlotes .timeBlocks p {
        font-size: 12px;
    }

    .coinCardshopModal .modal-content {
        border-radius: 50px 0px 33px 33px !important;
        margin-top: 5%;
        padding: 10px 10px;
    }

    .coinCardshopModal .modal-content .modal-body {
        padding: 0px 5px 5px;
    }

    .coinCardshopModal .modal-content .modal-body div>img {
        height: 30px !important;
        object-fit: contain;
    }

    .coinCardshopModal .modal-content .modal-body p {
        font-size: 30px !important;
        margin-bottom: 2px;
    }

    .cpCardGraph p {
        font-size: 11px !important;
        margin-bottom: 0px !important;
    }

    .coinCardshopModal .modal-dialog-centered {
        align-items: center !important;
        display:flex !important;
    }

    .coinCardshopModal .modal-body button {
        height: 50px;
        padding: 20px 20px;
        /* margin-top: 25px; */
    }

    .gauge_responisve {
        height: 140px !important;
    }

    .BigDiv_Responsive .textbox .mid {
        top: 5% !important;
    }

    .BigDiv_Responsive .textbox * {
        font-size: 12px !important;
    }

    .cpCardGraph .range_detail_logo {
        height: auto !important;
        object-fit: contain;
    }

    .cpCardGraph .text_timer_responsive {
        font-size: 18px !important;
        left: 42% !important;
    }
}

@media screen and (min-device-width: 250px) and (max-device-width: 767px) { 
    .BigDiv_Responsive .textbox .low {
        left: 17% !important;
        top: 43% !important;
    }
    .BigDiv_Responsive .textbox .high {
        right: 17% !important;
        top: 43% !important;
    }

    .GoodModal {
        display:flex !important;
        justify-content: center !important;
        padding : 30px 0px 0px !important;
    }

    .icon {
        max-width:30px !important;
        max-height:30px !important;
    }

    .coinCardshopModal .modal-content{
        border-radius: 50px 0px 33px 33px !important;
        margin-top: 5%;
    }

    .cpCardGraph .range_detail_logo {
        max-width:35px !important;
        max-height:35px !important;
    }

    /* .cpCardGraph {
        margin-top: 60% !important;
    } */
}

@media screen and (min-device-width: 250px) and (max-device-width: 990px) { 

    .cpCardGraph .range_detail_logo {
        max-width:35px !important;
        max-height:35px !important;
    }

 
}


@media only screen and (max-width: 575px) {
    .frameCoinshow .frameCoinHeader .frameheadLogo img {
        max-width: 30px;
        max-height: 30px;
    }

    .frameCoinshow .confetti-button {
        max-width: 100px;
        height: 40px;
    }

    .frameCoinshow .frameHeadcpLogo img {
        max-width: 50px;
    }

    .cpCardGraph p {
        font-size: 10px !important;
    }

    .timeSlotes .timeBlocks {
        width: 55px !important;
        height: 55px !important;
        flex: 0 0 55px;
    }

    .timeSlotes .timeBlocks p {
        font-size: 12px;
    }

    .good-job-body {padding-bottom: 40px !important;}
}

.iconText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.goodJobModal .modal-content{
    max-width: 250px;
    border-radius: 50px 0px 50px 50px !important;
}

.goodJobModal .modal-content .appr-title{
    font-size: 30px;
    color: green;
    font-weight: 300;
    margin-bottom: 0px;
    /* padding-top: 25px; */
}

.goodJobModal button  {
    font-size: 17px;
    padding: 24px 30px;
}

.goodJobModal .btn-close {
    padding: 10px 10px;
}

.rangeUI {
    display:flex;
    justify-content: center;
    align-items: center;
}
