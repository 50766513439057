.containerValue {  
    /* position:relative; */
   /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */
  padding: 20px 0px;
  /* border-radius: 20px; */
  width: 95%;
  margin: 0 auto;
  /* height: 100px; */
  left: -18px;

}
.Fullbarimpact{
display: flex;
height: 48px;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  left: 0px;
  top: 0px;  
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */
}
.boxes {
  position: relative;
  z-index: 1;
  padding: 1px;
  display: flex;
  width: 25%;
  background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box;
  /* gap: 1px; */
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */  
}
.backGroundBox{
 position:relative;
 display: flex;
 width: 100%;
 gap: 1.5px;
 border-radius: 10px 0px 0px 10px;
 /* background: linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%); */
 overflow: hidden;
}

.whiteBox{
position: absolute;
  top: 0;
  left: 0;   
  height: 100%;  
  background-color: white;
  z-index: 1;
}

.backGroundBox2{
  position:relative;
 display: flex;
 width: 100%;
 gap: 1.5px;
 border-radius: 0px 10px 10px 0px;
 overflow: hidden;
 /* background: linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%); */
}
.whiteBox2{
position: absolute;
  top: 0;
  /* left: 0;   */
  right: 0;
  height: 100%;
  /* border-radius: 0px 10px 10px 0px; */
  transition: 'width 0.5s ease';
  background-color: white; /* Your desired background color */
  z-index: 1; /* Ensures it's on top */
}

.boxchild {  
  background-color: #ffffff;
  border: 1.5px solid #ffffff;  
  border-radius: 4px;
  z-index: 2;
    width: 20%; 
    height: 30px;
    background: none;
}

.centerBox {
  z-index: 2;
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center; 
  /* border: 1px solid #4b3dff; */
  border-radius: 2px;
  height: 60px; 
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */
}

.team {  
  background-color: #ffffff;
  padding: 13px;
  /* border-radius: 2px; */
  font-weight: bold;
  margin: 0px 2px;
}
.vs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vs1 {  
      /* margin-top: -8px; */
    width: 1px;
    height: 13px;
    /* height: 6px; */
    background: #4b3dff;
}
.vs2 {  
  width: 10px;
    height: 1px;
    background: #4b3dff;  
}
.vs3 {      
  padding: 1px 12px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 1rem;
    color: #4b3dff;
}


.VoteArrow{
    position: absolute;
    font-size: 15px;
    bottom:-15px;
    color: #4b3dff;
    transform: rotate(180deg);
}

.DarkCoin{
background-color: #6352e8 !important;
color: white !important;
/* box-shadow: rgb(67 47 229) 0px 4px 1px, rgb(170 164 220) 0px 8px 6px !important; */
}

.DarkCoin .svg-path {
 stroke: white!important;
}


@media (max-width: 767px) {
  /* .boxchild {  
    width: 20px; 
    height: 20px; 
  } */

  .team{
    padding: 8px;
  }
  .vs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vs1 {      
    height: 11px;  
      /* margin-top: -6px;         */
}
.vs3 {     
  padding: 1px 8px;
  font-size: 0.7rem;
}
  .centerBox{
    /* height: 40px;
    width: 36%; */
  }
}